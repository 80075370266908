.MuiBox-root-922{
    padding:24px 0px 24px 0px !important;   
}
.MuiBox-root-1890{
    padding:24px 0px 24px 0px !important; 
}

.MuiBox-root-3342{
    padding:24px 0px 24px 0px !important; 
}

.MuiBox-root-4794  {
    padding:24px 0px 24px 0px !important;
}

.MuiBox-root-7214{
    padding:24px 0px 24px 0px !important;
}
.MuiBox-root-8182{
    padding:24px 0px 24px 0px !important;
}

.MuiBox-root-2374{
    padding:24px 0px 24px 0px !important;
}

.MuiBox-root-2448{
    padding:24px 0px 24px 0px !important;
}